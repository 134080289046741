/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import CircledPolicyTypeIcon from '../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ShieldCheckIcon } from '../../../../components/core/icons';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import { ICandidate, ILead } from '../../../../interfaces';
import { IQuote, QuoteKind } from '../../../../interfaces/IQuote';
import { ISourceDimensions, MarketingOpportunities } from '../../../../interfaces/ISourceDimensions';
import useMarketingOpportunity from '../../../../queries/marketing_opportunities/useMarketingOpportunity';
import useQuotesRequests from '../../../../queries/quotes_requests/useQuotesRequests';
import colors from '../../../../theme/colors';
import { borderRadius, spacings } from '../../../../theme/variables';
import { dateTimeFormatter, moneyFormatter, SCOUTING_REPORT_DATE_TIME_FORMAT } from '../../../../utils/formatter';
import ExternalLinkModal from '../../GuidedQuoting/Quotes/_components/ExternalLinkModal';
import Bridge from '../../GuidedQuoting/Quotes/_components/QuoteRow/Bridge';
import useCustomerChoices from '../../GuidedQuoting/Quotes/_hooks/useCustomerChoices';

interface IMarketingOpportunityContextInfo {
  personGid: string;
  lead: ILead | undefined;
  candidate: ICandidate | undefined;
  sourceDimensions: ISourceDimensions | undefined;
}

const MarketingOpportunityContextInfo = ({
  personGid,
  lead,
  candidate,
  sourceDimensions
}: IMarketingOpportunityContextInfo) => {
  const { data: marketingOpportunity } = useMarketingOpportunity(sourceDimensions?.marketing_opportunity);
  const { data: quotesRequests } = useQuotesRequests({ personGid, leadGid: lead?.gid });
  const { customerChoices } = useCustomerChoices({ personGid, leadGid: lead?.gid, quotesRequests });

  const [selectedQuote, setSelectedQuote] = React.useState<IQuote | undefined>(undefined);

  const isMarketingOpportunityKnown =
    marketingOpportunity && sourceDimensions?.marketing_opportunity !== MarketingOpportunities.Unknown;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!isMarketingOpportunityKnown && !customerChoices) {
    return null;
  }

  return (
    <Container data-testid="context">
      <Text type="large" bold>
        Context
      </Text>
      {isMarketingOpportunityKnown && (
        <>
          <FlexBox mt={spacings.px12} justifySpaceBetween>
            <Text>{marketingOpportunity.label}</Text>
            {(lead || candidate) && (
              <Text color={colors.grey60} data-testid="creation-date">
                {dateTimeFormatter(lead?.created_at || candidate?.created_at, SCOUTING_REPORT_DATE_TIME_FORMAT)}
              </Text>
            )}
          </FlexBox>
          <FlexBox mt={spacings.px12}>
            <Text color={colors.grey60}>{marketingOpportunity.description}</Text>
          </FlexBox>
        </>
      )}
      {!!customerChoices?.length && (
        <FlexBox mt={spacings.px32} gap={spacings.px8} columnDirection>
          {customerChoices.map(customerChoice => (
            <FlexBox key={customerChoice.gid}>
              <CircledPolicyTypeIcon policyType={customerChoice.attributes.policy_type} />
              <FlexBox columnDirection fitParentWidth ml={spacings.px8} gap={spacings.px4}>
                <FlexBox alignItemsCenter justifySpaceBetween>
                  <FlexBox
                    gap={spacings.px4}
                    alignItemsCenter
                    customCss={css`
                      width: 120px;
                    `}
                  >
                    <span
                      data-tip={customerChoice.attributes.carrier.name}
                      data-for="carrier-name-tooltip"
                      css={css`
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                      `}
                    >
                      <Text bold>{customerChoice.attributes.carrier.name}</Text>
                    </span>
                    <Bridge quote={customerChoice} compact onBridge={() => setSelectedQuote(customerChoice)} />
                  </FlexBox>
                  <Tag label="Customer’s choice" backgroundColor={colors.violet} borderRadius={borderRadius} />
                </FlexBox>
                <FlexBox alignItemsCenter justifySpaceBetween>
                  {customerChoice.attributes.premium ? (
                    <FlexBox alignItemsCenter gap={spacings.px4}>
                      {customerChoice.kind === QuoteKind.RC2 && (
                        <ShieldCheckIcon
                          color={colors.statusGreen}
                          data-tip="Near final price with reports"
                          data-for="near-final-price-tooltip"
                        />
                      )}
                      <Text>{moneyFormatter(customerChoice.attributes.premium, true)}</Text>
                      {!!customerChoice.attributes.bundled_premium && (
                        <Text color={colors.statusGreen}>
                          With bundle: {moneyFormatter(customerChoice.attributes.bundled_premium, true)}
                        </Text>
                      )}
                    </FlexBox>
                  ) : (
                    <div />
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          ))}
        </FlexBox>
      )}
      <Tooltip id="carrier-name-tooltip" />
      <Tooltip id="near-final-price-tooltip" />
      {selectedQuote && (
        <ExternalLinkModal
          bindingUrl={selectedQuote.attributes.binding_url}
          externalId={selectedQuote.attributes.external_id}
          notice={selectedQuote.attributes.notice}
          carrier={selectedQuote.attributes.carrier}
          cancelBtnHandler={() => setSelectedQuote(undefined)}
        />
      )}
    </Container>
  );
};

export default MarketingOpportunityContextInfo;
