import * as React from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import AgentAssistIntegration from '../../components/AgentAssistIntegration';
import HappyBirthday from '../../components/HappyBirthday';
import TaskReminders from '../../components/TaskReminders/TaskReminders';
import TwilioIntegration from '../../components/TwilioIntegration';
import UserGuiding from '../../components/UserGuiding';
import appConfiguration from '../../constants/appConfiguration';
import featureFlags from '../../constants/featureFlags';
import { useEntraActionCableConsumer } from '../../hooks';
import { ActionCableContext } from '../../utils/actioncable';

const EntraExtrasLayoutRoute = () => {
  const cableConsumer = useEntraActionCableConsumer();

  const isEmbeddedIntoZendesk = !!useMatch('/people/:personGid/communication_history');

  return (
    <ActionCableContext.Provider value={cableConsumer}>
      <TaskReminders />
      <UserGuiding environment={appConfiguration.environment as 'staging' | 'production'} />
      <HappyBirthday />
      {!featureFlags.hideCallIndicator && !isEmbeddedIntoZendesk && <TwilioIntegration />}
      {featureFlags.newQuotesUI && <AgentAssistIntegration />}
      <Outlet />
    </ActionCableContext.Provider>
  );
};

export default EntraExtrasLayoutRoute;
