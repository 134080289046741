/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import * as React from 'react';

import HomeLinks from '../../../../components/common/HomeLinks';
import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import SystemMessage from '../../../../components/core/Alert/SystemMessage';
import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { AddressField, InputField, MultiSelectField, SelectField } from '../../../../components/core/forms/fields';
import { answerForDatapoint } from '../../../../components/core/forms/workflowHelpers';
import { VisibilityOffIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../../components/core/QuestionVerificationStatusIcon';
import SwitchToggle from '../../../../components/core/SwitchToggle';
import Tag from '../../../../components/core/Tag';
import { Translations } from '../../../../constants';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../../../constants/addressForm';
import { useToggle } from '../../../../hooks';
import {
  IAddressSuggestion,
  ICustomerDataCompleteness,
  IDataCollectionPage,
  IOpportunity,
  IPerson,
  IPersonAddress,
  IRelatedPerson,
  IUIFlow
} from '../../../../interfaces';
import DatapointKey from '../../../../interfaces/IDatapoint';
import { homeUsageTitle, IHome, isExtendedHomeInfoAvailable } from '../../../../interfaces/IHome';
import { OpportunityStatus } from '../../../../interfaces/IOpportunity';
import { QuestionVerificationStatus } from '../../../../interfaces/IWorkflow';
// eslint-disable-next-line max-len
import { useUpdateOpportunityDataCollectionStatus } from '../../../../queries/leads/data_collection/useDataCollectionOpportunities';
import { Qualification } from '../../../../queries/people/usePersonAssetQualification';
import {
  PERSON_RELATED_PEOPLE_QUERY_KEY,
  useCreateRelatedPerson
} from '../../../../queries/people/usePersonRelatedPeople';
import analytics from '../../../../services/analytics';
import { isISR } from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { InputSize, spacings } from '../../../../theme/variables';
import { createSmartyStreetsMapping, renameKeys } from '../../../../utils/object';
import { isCustomerDataCompletenessHigh } from '../../_helpers';
import { isYearBuiltDisplayed } from '../HomeProfilePage';
import DisqualifySection from './DisqualifySection';
import HomeDeleteModal from './HomeDeleteModal';
import OpportunityLicensingWarning from './OpportunityLicensingWarning';
import RelatedPersonFormModal from './RelatedPersonFormModal';

interface IHomeProfileQuestions {
  index: number;

  person: IPerson;
  leadGid: string;
  leadId: number;
  personHomeGid: string;

  page: IDataCollectionPage;
  opportunity: IOpportunity;
  home: IHome;
  otherOpportunities: IOpportunity[];
  personAssetQualification: Qualification | false;
  personAddresses: IPersonAddress[] | undefined;
  homeProfileUIFlow: IUIFlow;
  formValues: Record<string, any>;
  relatedPeople: IRelatedPerson[] | undefined;

  isHomePrimary: boolean;
  isDataEditingForbidden: boolean;
  customerDataCompleteness: ICustomerDataCompleteness | undefined;

  onDisqualify: () => void;
  onLeadClosed: () => void;
}

const HomeProfileQuestions = ({
  index,

  person,
  leadGid,
  leadId,
  personHomeGid,

  page,
  opportunity,
  home,
  otherOpportunities,
  personAssetQualification,
  personAddresses,
  homeProfileUIFlow: uiFlow,
  formValues,
  relatedPeople,

  isHomePrimary,
  isDataEditingForbidden,
  customerDataCompleteness,

  onDisqualify,
  onLeadClosed
}: IHomeProfileQuestions) => {
  const { setFieldValue } = useFormikContext();
  const personGid = person.gid;
  const [showDeleteHomeModal, setShowDeleteHomeModal] = React.useState(false);
  const [addRelatedPersonModalShown, toggleAddRelatedPersonModal] = useToggle(false);
  const { mutateAsync: updateOpportunityDataCollectionStatus, isPending: isUpdatingOpportunityDataCollectionStatus } =
    useUpdateOpportunityDataCollectionStatus();

  const propertyAddressLabel = isISR() && page.is_skippable ? 'Garaging address' : 'Property address';

  const { mutateAsync: addRelatedPerson, isPending: isAddingRelatedPerson } = useCreateRelatedPerson();

  const disableField =
    opportunity.status === OpportunityStatus.NotQualified ||
    isDataEditingForbidden ||
    !opportunity.is_data_collection_enabled;

  const propertyAddressQuestion = uiFlow?.questionForDatapoint(DatapointKey.PropertyAddress)?.content;
  const mailingAddressQuestion = uiFlow?.questionForDatapoint(DatapointKey.PersonMailingAddress)?.content;
  const propertyUsageTypeQuestion = uiFlow?.questionForDatapoint(DatapointKey.PropertyUsageType)?.content;
  const propertyOwnershipTypeQuestion = uiFlow?.questionForDatapoint(DatapointKey.PropertyOwnershipType)?.content;
  const propertyYearBuiltQuestion = uiFlow?.questionForDatapoint(DatapointKey.PropertyYearBuilt)?.content;
  const propertyOnTheDeedQuestion = uiFlow?.questionForDatapoint(DatapointKey.PropertyOnTheDeed)?.content;
  const propertyTrustNameQuestion = uiFlow?.questionForDatapoint(DatapointKey.PropertyTrustName)?.content;
  const propertyLLCNameQuestion = uiFlow?.questionForDatapoint(DatapointKey.PropertyLLCName)?.content;

  // See comment at UIFlowQuestionAnswerPair why these checks are necessary
  const propertyAddressVerificationStatus =
    answerForDatapoint(uiFlow?.answers, DatapointKey.PropertyAddress) &&
    isCustomerDataCompletenessHigh(customerDataCompleteness)
      ? QuestionVerificationStatus.Verified
      : propertyAddressQuestion?.verification_status;

  const mailingAddressVerificationStatus =
    answerForDatapoint(uiFlow?.answers, DatapointKey.PersonMailingAddress) &&
    isCustomerDataCompletenessHigh(customerDataCompleteness)
      ? QuestionVerificationStatus.Verified
      : mailingAddressQuestion?.verification_status;

  const propertyOwnershipVerificationStatus =
    answerForDatapoint(uiFlow?.answers, DatapointKey.PropertyOwnershipType) &&
    isCustomerDataCompletenessHigh(customerDataCompleteness)
      ? QuestionVerificationStatus.Verified
      : propertyOwnershipTypeQuestion?.verification_status;

  const buildDeedList = () => {
    const relatedPeopleList = (relatedPeople || [])
      .map(person => ({
        value: person.gid,
        label: person.name,
        description: Translations.relationKind(person.kind) || '',
        isDisabled: false
      }))
      .concat([
        { value: 'llc', label: 'LLC', description: '', isDisabled: false },
        { value: 'trust', label: 'Trust', description: '', isDisabled: false }
      ]);

    return [{ value: personGid, label: person.name, description: 'Primary contact', isDisabled: true }].concat(
      relatedPeopleList
    );
  };

  const queryClient = useQueryClient();

  const isDeedQuestionShown = isExtendedHomeInfoAvailable({
    ownership: formValues[DatapointKey.PropertyOwnershipType]
  });

  return (
    <Container key={personHomeGid} mt={spacings.px24}>
      <OpportunityLicensingWarning opportunity={opportunity} mb={spacings.px8} />
      {showDeleteHomeModal && (
        <HomeDeleteModal
          confirmBtnHandler={homeGid => {
            analytics.track('Home removed', {
              lead_gid: leadGid,
              person_gid: personGid,
              place: 'guided_selling_experience',
              home_gid: homeGid
            });
          }}
          cancelBtnHandler={() => setShowDeleteHomeModal(false)}
          leadId={leadId}
          home={home}
          personGid={personGid}
          nonPrimaryOpportunities={isHomePrimary ? otherOpportunities : []}
        />
      )}
      {addRelatedPersonModalShown && (
        <RelatedPersonFormModal
          cancelHandler={toggleAddRelatedPersonModal}
          isLoading={isAddingRelatedPerson}
          confirmHandler={async values => {
            const {
              related_person: { gid }
            } = await addRelatedPerson({
              sourcePersonGid: personGid!,
              data: values
            });

            queryClient.invalidateQueries({ queryKey: [PERSON_RELATED_PEOPLE_QUERY_KEY, personGid] });

            analytics.track('Related person added', {
              lead_gid: leadGid,
              person_gid: personGid,
              place: 'guided_selling_experience'
            });

            const currentDeedValue = formValues[DatapointKey.PropertyOnTheDeed];

            setFieldValue(`${personHomeGid}.${DatapointKey.PropertyOnTheDeed}`, currentDeedValue.concat(gid));

            toggleAddRelatedPersonModal();
          }}
        />
      )}
      <FlexBox gap={spacings.px8} alignItemsCenter justifySpaceBetween>
        <FlexBox gap={spacings.px8} alignItemsCenter>
          {!opportunity.is_data_collection_enabled && (
            <VisibilityOffIcon width={spacings.px20} height={spacings.px20} />
          )}
          <Paragraph type="large" bold color={!opportunity.is_data_collection_enabled ? colors.grey60 : colors.black}>
            {homeUsageTitle(home)}
          </Paragraph>
          {isHomePrimary && (
            <Tag
              label="Primary opportunity"
              transparent
              backgroundColor={colors.violet}
              textColor={colors.violet}
              mt={spacings.px4}
            />
          )}
          {personGid && <HomeLinks personGid={personGid} homeGid={personHomeGid} />}
          <IconButton
            id="home-profile-delete-home"
            data-testid={`home-profile-delete-home-${personHomeGid}`}
            icon={ButtonIcons.Delete}
            color={colors.grey60}
            onClick={() => setShowDeleteHomeModal(true)}
            disabled={isDataEditingForbidden}
          />
        </FlexBox>
        <FlexBox alignItemsCenter gap={spacings.px4}>
          <Paragraph type="small">
            Data collection {opportunity.is_data_collection_enabled ? 'enabled' : 'disabled'}
          </Paragraph>
          <SwitchToggle
            checked={opportunity.is_data_collection_enabled}
            testId={`${index}-home-profile-data-collection-toggle`}
            onChange={() => {
              analytics.track(
                `Home data collection ${opportunity.is_data_collection_enabled ? 'disabled' : 're-enabled'}`,
                {
                  lead_gid: leadGid,
                  person_gid: personGid,
                  opportunity_id: opportunity.id
                }
              );

              updateOpportunityDataCollectionStatus({
                leadId,
                opportunitiesIds: [opportunity.id],
                dataCollectionEnabled: !opportunity.is_data_collection_enabled
              });
            }}
            disabled={isUpdatingOpportunityDataCollectionStatus || isDataEditingForbidden}
          />
        </FlexBox>
      </FlexBox>

      <FlexBox mt={spacings.px8} pl={spacings.px16} columnDirection>
        <FlexBox gap={spacings.px4} alignItemsCenter>
          {propertyAddressVerificationStatus && (
            <QuestionVerificationStatusIcon
              verificationStatus={propertyAddressVerificationStatus}
              disabled={disableField}
              css={css`
                margin-left: -${spacings.px20}px;
              `}
            />
          )}
          <AddressField
            disabled={disableField}
            required={opportunity.is_data_collection_enabled}
            key={`${personHomeGid}.${DatapointKey.PropertyAddress}`}
            label={propertyAddressLabel}
            id={`${personHomeGid}.${DatapointKey.PropertyAddress}`}
            testId={`${index}-home-profile-property-address`}
            inline
            defaultValue={
              renameKeys(API_TO_SMARTY_STREETS_MAPPING, formValues[DatapointKey.PropertyAddress]) as IAddressSuggestion
            }
            defaultOptions={
              (personAddresses?.map(address => renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)) ||
                []) as IAddressSuggestion[]
            }
            fallbackNames={createSmartyStreetsMapping(`${personHomeGid}.${DatapointKey.PropertyAddress}.`)}
            inputSize={InputSize.Large}
          />
        </FlexBox>
        {isHomePrimary && (
          <FlexBox gap={spacings.px4} alignItemsCenter>
            {mailingAddressVerificationStatus && (
              <QuestionVerificationStatusIcon
                verificationStatus={mailingAddressVerificationStatus}
                disabled={disableField}
                css={css`
                  margin-left: -${spacings.px20}px;
                `}
              />
            )}
            <AddressField
              disabled={disableField}
              required={opportunity.is_data_collection_enabled}
              inline
              key={`${personHomeGid}.${DatapointKey.PersonMailingAddress}`}
              label="Mailing address"
              id={`${personHomeGid}.${DatapointKey.PersonMailingAddress}`}
              testId={`${index}-home-profile-mailing-address`}
              defaultValue={
                renameKeys(
                  API_TO_SMARTY_STREETS_MAPPING,
                  formValues[DatapointKey.PersonMailingAddress]
                ) as IAddressSuggestion
              }
              defaultOptions={
                (personAddresses?.map(address => renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)) ||
                  []) as IAddressSuggestion[]
              }
              fallbackNames={createSmartyStreetsMapping(`${personHomeGid}.${DatapointKey.PersonMailingAddress}.`)}
              inputSize={InputSize.Large}
            />
          </FlexBox>
        )}
        <FlexBox gap={spacings.px4} alignItemsCenter>
          {propertyUsageTypeQuestion?.verification_status && (
            <QuestionVerificationStatusIcon
              verificationStatus={propertyUsageTypeQuestion.verification_status}
              disabled={disableField}
              css={css`
                margin-left: -${spacings.px20}px;
              `}
            />
          )}
          <SelectField
            required={opportunity.is_data_collection_enabled}
            inline
            disabled={disableField}
            key={`${personHomeGid}.${DatapointKey.PropertyUsageType}`}
            label="Residence type"
            id={`${personHomeGid}.${DatapointKey.PropertyUsageType}`}
            name={`${personHomeGid}.${DatapointKey.PropertyUsageType}`}
            testId={`${index}-home-profile-residence-type`}
            options={Translations.homeUsageOptions}
          />
        </FlexBox>
        <FlexBox gap={spacings.px4} alignItemsCenter>
          {propertyOwnershipVerificationStatus && (
            <QuestionVerificationStatusIcon
              verificationStatus={propertyOwnershipVerificationStatus}
              disabled={disableField}
              css={css`
                margin-left: -${spacings.px20}px;
              `}
            />
          )}
          <SelectField
            required={opportunity.is_data_collection_enabled}
            inline
            disabled={disableField}
            key={`${personHomeGid}.${DatapointKey.PropertyOwnershipType}`}
            label="Do you own or rent this property?"
            id={`${personHomeGid}.${DatapointKey.PropertyOwnershipType}`}
            name={`${personHomeGid}.${DatapointKey.PropertyOwnershipType}`}
            testId={`${index}-home-profile-ownership-type`}
            options={Translations.homeOwnershipOptions}
          />
        </FlexBox>
        {isYearBuiltDisplayed({
          address: formValues[DatapointKey.PropertyAddress],
          ownership: formValues[DatapointKey.PropertyOwnershipType]
        }) && (
          <FlexBox gap={spacings.px4} alignItemsCenter>
            {propertyYearBuiltQuestion?.verification_status && (
              <QuestionVerificationStatusIcon
                verificationStatus={propertyYearBuiltQuestion.verification_status}
                disabled={disableField}
                css={css`
                  margin-left: -${spacings.px20}px;
                `}
              />
            )}
            <InputField
              required={opportunity.is_data_collection_enabled}
              inline
              disabled={disableField}
              key={`${personHomeGid}.${DatapointKey.PropertyYearBuilt}`}
              label="Year built"
              id={`${personHomeGid}.${DatapointKey.PropertyYearBuilt}`}
              name={`${personHomeGid}.${DatapointKey.PropertyYearBuilt}`}
              type="number"
              testId={`${index}-home-profile-year-built`}
            />
          </FlexBox>
        )}
        {isDeedQuestionShown && (
          <>
            <FlexBox gap={spacings.px4} alignItemsCenter>
              {propertyOnTheDeedQuestion?.verification_status && (
                <QuestionVerificationStatusIcon
                  verificationStatus={propertyOnTheDeedQuestion.verification_status}
                  disabled={disableField}
                  css={css`
                    margin-left: -${spacings.px20}px;
                  `}
                />
              )}
              <MultiSelectField
                fsMask
                disabled={disableField}
                required={opportunity.is_data_collection_enabled}
                key={`${personHomeGid}.${DatapointKey.PropertyOnTheDeed}`}
                label="Who is listed on the deed of the property?"
                name={`${personHomeGid}.${DatapointKey.PropertyOnTheDeed}`}
                id={`${personHomeGid}.${DatapointKey.PropertyOnTheDeed}`}
                options={buildDeedList()}
                labelName="label"
                valueName="value"
                inline
                testId={`${index}-home-profile-deed-list`}
                menuOptions={
                  <Button
                    onClick={toggleAddRelatedPersonModal}
                    variant={ButtonVariant.Text}
                    data-testid={`${index}-home-profile-add-another-person-to-the-deed`}
                    customCss={css`
                      padding: ${spacings.px12}px;
                    `}
                  >
                    + Add another person
                  </Button>
                }
              />
            </FlexBox>
          </>
        )}

        {formValues[DatapointKey.PropertyOnTheDeed].includes('llc') && (
          <>
            <FlexBox gap={spacings.px4} alignItemsCenter>
              {propertyLLCNameQuestion?.verification_status && (
                <QuestionVerificationStatusIcon
                  verificationStatus={propertyLLCNameQuestion.verification_status}
                  disabled={disableField}
                  css={css`
                    margin-left: -${spacings.px20}px;
                  `}
                />
              )}
              <InputField
                fsMask
                label="LLC name"
                id={`${personHomeGid}.${DatapointKey.PropertyLLCName}`}
                name={`${personHomeGid}.${DatapointKey.PropertyLLCName}`}
                required
                inline
                testId={`${index}-home-profile-llc-name`}
                customLabelCss={css`
                  color: ${colors.grey60};
                `}
              />
            </FlexBox>
          </>
        )}
        {formValues[DatapointKey.PropertyOnTheDeed].includes('trust') && (
          <>
            <FlexBox gap={spacings.px4} alignItemsCenter>
              {propertyTrustNameQuestion?.verification_status && (
                <QuestionVerificationStatusIcon
                  verificationStatus={propertyTrustNameQuestion.verification_status}
                  disabled={disableField}
                  css={css`
                    margin-left: -${spacings.px20}px;
                  `}
                />
              )}
              <InputField
                fsMask
                label="Trust name"
                id={`${personHomeGid}.${DatapointKey.PropertyTrustName}`}
                name={`${personHomeGid}.${DatapointKey.PropertyTrustName}`}
                required
                inline
                testId={`${index}-home-profile-trust-name`}
                customLabelCss={css`
                  color: ${colors.grey60};
                `}
              />
            </FlexBox>
          </>
        )}
      </FlexBox>
      {personAssetQualification && (
        <DisqualifySection
          personAssetQualification={personAssetQualification}
          leadId={leadId}
          opportunityId={opportunity.id}
          onDisqualify={onDisqualify}
          onLeadClosed={onLeadClosed}
        />
      )}
      {opportunity.disqualification_reason && (
        <FlexBox>
          <SystemMessage
            type="warning"
            mt={spacings.px8}
            mb={spacings.px8}
            description={opportunity.disqualification_message || ''}
          />
        </FlexBox>
      )}
      <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
    </Container>
  );
};

export default HomeProfileQuestions;
