/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import NewTooltip from '../../../../components/common/Tooltip/NewTooltip';
import FlexBox from '../../../../components/core/FlexBox';
import Checkbox from '../../../../components/core/inputs/Checkbox';
import Select from '../../../../components/core/inputs/Select';
import Text from '../../../../components/core/Text';
import { Translations } from '../../../../constants';
import featureFlags from '../../../../constants/featureFlags';
import { updateBusinessType } from '../../../../queries/leads/business_type/useUpdateBusinessType';
import { updateLeadCaptureMethod } from '../../../../queries/leads/lead_capture_method/useUpdateLeadCaptureMethod';
import { updateLeadType } from '../../../../queries/leads/lead_type/useUpdateLeadType';
import { updateLeadPartner } from '../../../../queries/leads/partner/useUpdateLeadPartner';
import { updateTestMode } from '../../../../queries/leads/test_mode/useUpdateTestMode';
import useLead from '../../../../queries/leads/useLead';
import usePartners from '../../../../queries/partners/usePartners';
import authInfo from '../../../../services/authInfo';
import { spacings } from '../../../../theme/variables';
import AdminLeadDispositions from './AdminLeadDispositions';
import LeadDispositions from './LeadDispositions';

const LeadOperations = ({ personGid, leadId, leadGid }: { personGid: string; leadId: number; leadGid: string }) => {
  const { data: lead, refetch: refetchLead, isFetching: isFetchingLead } = useLead(leadGid);
  const { test_mode: testMode, source_dimensions: sourceDimensions } = lead || {};
  const {
    partner,
    lead_type: leadType,
    lead_capture_method: leadCaptureMethod,
    business_type: businessType
  } = sourceDimensions || {};

  const { data: partners } = usePartners();

  const dispositionsHistoryEditable = authInfo.features.dispositions_history_editable;
  const sourceDimensionsEditable = authInfo.features.edit_lead_source_dimensions;
  const testModeEditable = authInfo.features.lead_test_mode;
  const dispositions = () => {
    if (dispositionsHistoryEditable) {
      return <AdminLeadDispositions personGid={personGid} leadId={leadId} leadGid={leadGid} />;
    } else if (featureFlags.hideLeadDisposition) {
      null;
    } else {
      return <LeadDispositions personGid={personGid} leadId={leadId} leadGid={leadGid} />;
    }
  };

  const leadTypes = Translations.leadTypeOptions.map(option => {
    if (featureFlags.winBackLeadsChanges && option.key === 'win_back') {
      return { ...option, value: 'New business' };
    }
    return option;
  });

  return (
    <div
      css={css`
        width: 256px;
      `}
    >
      <Text type="large" bold>
        Lead operations
      </Text>
      <FlexBox columnDirection mt={spacings.px24}>
        {dispositions()}
      </FlexBox>

      <FlexBox mt={spacings.px16} columnDirection>
        <Text>Partner</Text>
        <Select
          css={css`
            margin-top: ${spacings.px4}px;
          `}
          name="partner"
          placeholder=""
          value={partner}
          options={partners?.map(({ key, name }) => ({ key, value: name })) || []}
          onChange={({ target }) => updateLeadPartner({ leadId, partner: target.value }).then(() => refetchLead())}
          disabled={isFetchingLead}
          ordered
          controlTestId="lead-operations-partner-select"
        />
      </FlexBox>

      <FlexBox mt={spacings.px16} columnDirection>
        <div>
          <Text>Lead type</Text>
          <span data-tip="Can be edited only by users with administrative role" data-for="disabled-lead-type">
            <Select
              css={css`
                margin-top: 4px;
              `}
              name="lead-type"
              placeholder=""
              value={leadType}
              options={leadTypes}
              onChange={({ target }) => updateLeadType({ leadId, leadType: target.value }).then(() => refetchLead())}
              disabled={!sourceDimensionsEditable || isFetchingLead}
              controlTestId="lead-operations-lead-type-select"
            />
          </span>
          {!sourceDimensionsEditable && <NewTooltip id="disabled-lead-type" />}
        </div>
      </FlexBox>

      <FlexBox mt={spacings.px16} columnDirection>
        <Text>Lead capture method</Text>
        <span data-tip="Can be edited only by users with administrative role" data-for="disabled-lead-capture-method">
          <Select
            css={css`
              margin-top: 4px;
            `}
            name="lead-capture-method"
            placeholder=""
            value={leadCaptureMethod}
            options={Translations.leadCaptureMethodOptions}
            onChange={({ target }) =>
              updateLeadCaptureMethod({ leadId, leadCaptureMethod: target.value }).then(() => refetchLead())
            }
            disabled={!sourceDimensionsEditable || isFetchingLead}
            controlTestId="lead-operations-lead-capture-method-select"
          />
        </span>
        {!sourceDimensionsEditable && <NewTooltip id="disabled-lead-capture-method" />}
      </FlexBox>

      <FlexBox mt={spacings.px16} columnDirection>
        <Text>Business type</Text>
        <Select
          css={css`
            margin-top: 4px;
          `}
          name="business-type"
          placeholder=""
          value={businessType}
          options={Translations.businessTypeOptions}
          onChange={({ target }) =>
            updateBusinessType({ leadId, businessType: target.value }).then(() => refetchLead())
          }
          disabled={!authInfo.features.edit_lead_source_dimensions}
          controlTestId="lead-operations-business-type-select"
        />
      </FlexBox>

      {testModeEditable && (
        <FlexBox mt={spacings.px16}>
          <Checkbox
            label="Mark as test lead"
            id="test-lead"
            name="test-lead"
            defaultChecked={testMode}
            onChange={() => updateTestMode({ leadId, testMode: !testMode }).then(() => refetchLead())}
            disabled={isFetchingLead}
            data-testid="lead-operations-test-mode-checkbox"
          />
        </FlexBox>
      )}
    </div>
  );
};

export default LeadOperations;
