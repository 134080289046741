/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import Copy from '../../components/core/Copy';
import FlexBox from '../../components/core/FlexBox';
import Text from '../../components/core/Text';
import { IQuote } from '../../interfaces';
import { coverageByKey, notRequiredHomeCoverages, PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { QuoteSource } from '../../interfaces/IQuote';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { moneyFormatter } from '../../utils/formatter';

const HomeQuoteInfo = ({
  setOpenQuoteEditor,
  quote,
  disabled
}: {
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  quote: IQuote;
  disabled?: boolean;
}) => {
  const carrierName = quote.attributes.carrier.name || '—';
  const premium = moneyFormatter(quote.attributes.premium, true) || '—';
  const dwelling = coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Dwelling)?.limit_description || '—';
  const deductible =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Dwelling)?.deductible_description || '—';
  const otherStructures =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.OtherStructures)?.limit_description || '—';
  const personalProperty =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.PersonalProperty)?.limit_description || '—';
  const lossOfUse = coverageByKey(quote.attributes.coverages, PolicyCoverageKey.LossOfUse)?.limit_description || '—';
  const personalLiability =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.PersonalLiability)?.limit_description || '—';
  const medicalPayments =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.MedicalPayments)?.limit_description || '—';

  const includedCoverages =
    quote.attributes.coverages
      ?.filter(
        coverage =>
          notRequiredHomeCoverages.includes(coverage.key) &&
          coverage.deductible_description !== 'Not included' &&
          coverage.limit_description !== 'Not included'
      )
      .map(({ description }) => description)
      .join(', ') || '—';

  const hurricane =
    includedCoverages.includes('Hurricane') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Hurricane)?.deductible_description;

  const windHail =
    includedCoverages.includes('Wind hail') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.WindHail)?.deductible_description;

  const waterBackup =
    includedCoverages.includes('Water backup') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.WaterBackup)?.limit_description;

  const earthquake =
    includedCoverages.includes('Earthquake') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Earthquake)?.limit_description;

  const flood =
    includedCoverages.includes('Flood') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Flood)?.limit_description;

  const exludedCoverages =
    quote.attributes.coverages
      ?.filter(
        coverage => coverage.limit_description === 'Not included' || coverage.deductible_description === 'Not included'
      )
      .map(({ description }) => description)
      .join(', ') || '—';

  const bindingUrl = quote.attributes.binding_url || '—';

  return (
    <Container
      border
      borderColor={colors.grey10}
      p={spacings.px12}
      customCss={css`
        border-radius: ${spacings.px8}px;
        max-width: 400px;
      `}
    >
      <FlexBox columnDirection gap={spacings.px8}>
        <FlexBox justifySpaceBetween>
          <Text bold>Your proposal</Text>
          {quote.source === QuoteSource.AU && !disabled && (
            <IconButton
              icon={ButtonIcons.Edit}
              color={colors.black}
              onClick={() => {
                setOpenQuoteEditor(true);
              }}
            />
          )}
        </FlexBox>
        <FlexBox gap={spacings.px12} columnDirection>
          <FlexBox justifySpaceBetween>
            <Text>Carrier</Text>
            <Text>{carrierName}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Bridge URL</Text>
            <Copy value={bindingUrl}>
              <Text
                customCss={css`
                  max-width: 160px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                `}
              >
                {bindingUrl}
              </Text>
            </Copy>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Premium(1 year)</Text>
            <Text>{premium}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Deductible</Text>
            <Text>{deductible}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Dwelling</Text>
            <Text>{dwelling}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Other structures</Text>
            <Text>{otherStructures}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Personal property</Text>
            <Text>{personalProperty}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Loss of use</Text>
            <Text>{lossOfUse}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Personal liability</Text>
            <Text>{personalLiability}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Medical payments</Text>
            <Text>{medicalPayments}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Coverages included</Text>
            <Text>{includedCoverages}</Text>
          </FlexBox>
          {hurricane && (
            <FlexBox justifySpaceBetween>
              <Text>Hurricane</Text>
              <Text>{hurricane}</Text>
            </FlexBox>
          )}
          {windHail && (
            <FlexBox justifySpaceBetween>
              <Text>Wind/Hail</Text>
              <Text>{windHail}</Text>
            </FlexBox>
          )}
          {waterBackup && (
            <FlexBox justifySpaceBetween>
              <Text>Water backup</Text>
              <Text>{waterBackup}</Text>
            </FlexBox>
          )}
          {earthquake && (
            <FlexBox justifySpaceBetween>
              <Text>Earthquake</Text>
              <Text>{earthquake}</Text>
            </FlexBox>
          )}
          {flood && (
            <FlexBox justifySpaceBetween>
              <Text>Flood</Text>
              <Text>{flood}</Text>
            </FlexBox>
          )}
          <FlexBox justifySpaceBetween>
            <Text>Coverages exluded</Text>
            <Text>{exludedCoverages}</Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Container>
  );
};
export default HomeQuoteInfo;
