import moment from 'moment';

import { ICarrier } from './ICarrier';
import { OpportunityCoveragesTier } from './IOpportunity';
import { PolicyCoverageKey } from './IPolicyCoverage';
import { InsurableInterest, PolicyType, Requirement } from './IPolicyType';

export enum EligibilityResults {
  Ineligible = 'ineligible',
  Eligible = 'eligible',
  Uncertain = 'uncertain'
}

interface EligibilityRule {
  explanation: string | null;
  guideline_icon: string | null;
  guideline_key: string;
  result: EligibilityResults;
}

export interface IQuoteEligibility {
  result: EligibilityResults;
  rules: EligibilityRule[];
}

export interface IQuoteCoverage {
  key: PolicyCoverageKey;
  asset_gid: string;
  limit_value?: string | number | null;
  limit_description: string;
  deductible_description: string | null;
  title: string;
  description: string;
  deductible_value?: string | number | null;
}

export interface PaymentOption {
  term_months?: number | null;
  bundle?: boolean | null;
  downpayment?: number | null;
  subsequent_payment?: number | null;
  full_term_premium?: number | null;
}

export interface IQuoteAttributes {
  premium: number | null;
  bundled_premium: number | null;
  carrier: ICarrier;
  asset_gids: string[] | null;
  external_id: string | null;
  binding_url: string | null;
  notice: string | null;
  message: string | null;
  feedback_url: string | null;
  policy_type: PolicyType;
  requirement: Requirement;
  insurable_interest: InsurableInterest;
  mpd_discount: string | null;
  carrier_non_admitted: boolean | null;
  carrier_non_standard: boolean | null;
  coverages_tier: OpportunityCoveragesTier | null;
  coverages: IQuoteCoverage[] | null;
  eligibility: IQuoteEligibility | null;
  is_customer_choice: boolean | null;
  promotion_message: string | null;
  promotion_rank: number | null;
  bundle_options:
    | {
        monoline_enabled: boolean;
        same_agency_bundle_discount_enabled: boolean;
        same_carrier_bundle_discount_enabled: boolean;
      }
    | undefined;
  coinsureds_gids: string[] | null;
  payment_options: PaymentOption[] | null;
}

export enum QuoteValueChange {
  Increase = 'increase',
  Decrease = 'decrease'
}

export enum QuoteKind {
  NonIntegrated = 'non_integrated',
  RC1 = 'rc1',
  RC2 = 'rc2'
}

export enum QuoteSource {
  QS = 'quoting', // rc1
  OLD_QS = 'quoting_service', // old constant should be migrated to QS
  SA = 'shadow_agent', // rc1 / rc2
  OLB = 'olb', // rc1 / rc2,
  ONLINE_BIND = 'online_bind', //rc1 / rc2,
  RE = 'recommendations_engine', // non_integrated
  AU = 'agency_user', // rc2
  CO = 'carriers_operative' // rc2
}

export enum QuoteStatus {
  Success = 'success',
  CompleteManually = 'complete_manually',

  CarrierError = 'carrier_error',
  InvalidQuote = 'invalid_quote',
  DeclinedBusiness = 'declined_business',
  InvalidData = 'invalid_data',
  QuotingServiceError = 'quoting_service_error',
  ProductUnavailable = 'product_unavailable',
  CredentialsExpired = 'credentials_expired',
  Unknown = 'unknown'
}

export enum QuoteState {
  Started = 'started',
  Completed = 'completed'
}

export interface IQuote {
  gid: string;
  created_at: string;
  kind: QuoteKind;
  source: QuoteSource;
  status: QuoteStatus;
  state: QuoteState;
  attributes: IQuoteAttributes;

  premiumChange?: number;
  premiumChangePercent?: number;
  premiumChangeType?: QuoteValueChange;

  predictedPremiumChange?: number;
  predictedPremiumChangeType?: QuoteValueChange;

  coverageChange?: number;
  coverageChangePercent?: number;
  coverageChangeType?: QuoteValueChange;
}

export const isMoreThanOneWeekOld = (createdAt: string) => {
  const currentDate = moment(new Date());
  const createdAtDate = moment(createdAt);

  return currentDate.diff(createdAtDate, 'days') > 7;
};
