// This type is designated for policy coverage that were provided by partners or entered by agent manually via UI,
// or quotes

export enum PolicyCoverageKey {
  Dwelling = 'dwelling',
  OtherStructures = 'other_structures',
  PersonalProperty = 'personal_property',
  LossOfUse = 'loss_of_use',
  PersonalLiability = 'personal_liability',
  MedicalPayments = 'medical_payments',

  Hurricane = 'hurricane',
  WindHail = 'wind_hail',
  WaterBackup = 'water_backup',
  Earthquake = 'earthquake',
  Flood = 'flood',

  BiPerPerson = 'bodily_injury_per_person',
  BiPerAccident = 'bodily_injury_per_accident',
  PropertyDamage = 'property_damage',
  UninsuredMpd = 'uninsured_motorist_property_damage',
  UninsuredMbiPerPerson = 'uninsured_motorist_bodily_injury_per_person',
  UninsuredMbiPerAccident = 'uninsured_motorist_bodily_injury_per_accident',
  UnderinsuredMbiPerPerson = 'underinsured_motorist_bodily_injury_per_person',
  UnderinsuredMbiPerAccident = 'underinsured_motorist_bodily_injury_per_accident',
  Comprehensive = 'comprehensive',
  Collision = 'collision',
  Towing = 'towing',
  FullGlass = 'full_glass',
  RentalPerDay = 'rental_per_day',
  RentalMaxAmount = 'rental_max_amount',
  PersonalInjuryProtection = 'personal_injury_protection',

  Jewelry = 'jewelry',

  FaceAmount = 'face_amount'
}

export enum LimitType {
  MoneyLimit = 'money_limit',
  MonthlyLimit = 'monthly',
  OtherLimit = 'other'
}

export enum DeductibleType {
  Flat = 'flat',
  Percentage = 'percentage'
}

export interface PolicyCoverage {
  gid: string;
  key: PolicyCoverageKey;
  limit_type: LimitType;
  limit_value: string;
  deductible_type: DeductibleType | null;
  deductible_value: string | null;
}

export const coverageByKey = <T extends { key: string }>(coverages: T[] | null | undefined, key: PolicyCoverageKey) =>
  coverages?.find(coverage => key === coverage.key);

export const notRequiredHomeCoverages = [
  PolicyCoverageKey.Hurricane,
  PolicyCoverageKey.WindHail,
  PolicyCoverageKey.WaterBackup,
  PolicyCoverageKey.Earthquake,
  PolicyCoverageKey.Flood
];
