/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { components } from 'react-select';

import Button, { ButtonVariant } from '../../components/core/buttons/Button';
import FlexBox from '../../components/core/FlexBox';
import Select from '../../components/core/inputs/Select';
import Text from '../../components/core/Text';
import { IQuote } from '../../interfaces';
import { InsurableInterest } from '../../interfaces/IPolicyType';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { DASHBOARD_DATE_TIME_FORMAT, dateTimeFormatter, moneyFormatter } from '../../utils/formatter';
import Deductible from '../GuidedSellingExperience/GuidedQuoting/Quotes/_components/QuoteRow/Deductible';

const QuoteSingleValue = ({ children, data: option, ...rest }: any) => {
  const term = option.term_months === 6 || option.insurableInterest === InsurableInterest.Vehicle ? '6 mo' : 'yr';

  return (
    <components.SingleValue {...rest}>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <div
          css={css`
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            height: 30px;
            width: 100px;
            ${option.carrierLogo ? `background-image: url(${option.carrierLogo})` : ''};
          `}
          title={option.carrierName}
        />
        <Text bold>
          {moneyFormatter(option.premium, true)}/{term}
        </Text>
      </FlexBox>
    </components.SingleValue>
  );
};

const QuoteOption = ({ children, data: option, ...rest }: any) => {
  const isMasked = (rest.selectProps as any)['fsMask'];
  const isSelected = rest.isSelected;
  const term = option.term_months === 6 || option.insurableInterest === InsurableInterest.Vehicle ? '6 mo' : 'yr';

  return (
    <components.Option {...rest} className={isMasked ? 'fs-mask' : ''}>
      <FlexBox columnDirection fitParentWidth m={spacings.px4} gap={spacings.px4}>
        <FlexBox justifySpaceBetween alignItemsFlexStart>
          <Text type="small" color={isSelected ? colors.white : colors.grey60}>
            {option.insurableInterest === InsurableInterest.RealProperty ? 'Deductible' : 'BI'}: {option.deductible}
          </Text>
          <Text type="small" singleLine color={isSelected ? colors.white : colors.grey60}>
            Generated {dateTimeFormatter(option.generated, DASHBOARD_DATE_TIME_FORMAT)}
          </Text>
        </FlexBox>
        <FlexBox justifySpaceBetween fitParentWidth alignItemsFlexEnd>
          <div
            css={css`
              background-position: left;
              background-repeat: no-repeat;
              background-size: contain;
              height: 30px;
              width: 100px;
              filter: ${isSelected ? 'invert(100%)' : ''} grayscale(100%);
              ${option.carrierLogo ? `background-image: url(${option.carrierLogo})` : ''};
            `}
            title={option.carrierName}
          />
          <Text bold color={isSelected ? colors.white : colors.black}>
            {moneyFormatter(option.premium, true)}/{term}
          </Text>
        </FlexBox>
      </FlexBox>
    </components.Option>
  );
};

const QuotesSelect = ({
  quotes,
  selectId,
  setOpenQuoteEditor,
  openQuoteEditor,
  selectedQuoteKey,
  setSelectedQuoteKey,
  loading,
  showResetButton,
  disabled
}: {
  quotes: IQuote[] | undefined;
  selectId: string;
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  openQuoteEditor: boolean;
  selectedQuoteKey: string | undefined;
  setSelectedQuoteKey: React.Dispatch<React.SetStateAction<string>>;
  loading?: boolean;
  showResetButton?: boolean;
  disabled?: boolean;
}) => {
  const quotesOptions =
    quotes
      ?.map(quote => {
        return {
          key: quote.gid,
          value: quote.gid,
          carrierLogo: quote.attributes.carrier.logo,
          carrierName: quote.attributes.carrier.name,
          deductible: (
            <Deductible
              quote={quote}
              type="small"
              color={selectedQuoteKey === quote.gid ? colors.white : colors.grey60}
            />
          ),
          insurableInterest: quote.attributes.insurable_interest,
          generated: quote.created_at,
          premium: quote.attributes.premium,
          term_months: quote.attributes.payment_options?.[0]?.term_months
        };
      })
      .filter(Boolean) || [];

  return (
    <Select
      customCss={css`
        margin: 12px 0;
      `}
      disabled={openQuoteEditor || disabled}
      name={selectId}
      options={quotesOptions}
      onChange={selected => {
        setSelectedQuoteKey(selected.target.value);
      }}
      showResetButton={showResetButton}
      value={selectedQuoteKey}
      placeholder="Select a quote"
      customComponents={{
        Option: QuoteOption as unknown as React.ReactNode,
        SingleValue: QuoteSingleValue as unknown as React.ReactNode
      }}
      isLoading={loading}
      customStyles={{
        control: () => ({
          display: 'flex',
          justifyContent: 'space-between',
          height: '44px',
          width: '400px',
          border: `1px solid ${colors.grey10}`,
          borderRadius: '8px'
        })
      }}
      createOptionFromSearch={false}
      hideDropDownIndicator={false}
      menuOptions={
        <>
          <hr
            css={css`
              margin: 0 10px 10px 10px;
            `}
          />
          <Button
            onClick={() => {
              setOpenQuoteEditor(true);
              setSelectedQuoteKey('');
            }}
            variant={ButtonVariant.PlainText}
            data-testid="enter-manually-button"
            customCss={css`
              margin: 4px 12px 16px 12px;
              text-align: left;
              width: 99%;
              color: ${colors.azure50};
            `}
          >
            Enter manually
          </Button>
        </>
      }
    />
  );
};

export default QuotesSelect;
