/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'debounce-promise';
import { Form, Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import HomeLinks from '../../../../components/common/HomeLinks';
import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../../components/core/Container';
import CopyableAddress from '../../../../components/core/CopyableAddress';
import FlexBox from '../../../../components/core/FlexBox';
import { ChevronRight, VisibilityOffIcon, WarningIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../../components/core/QuestionVerificationStatusIcon';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import { isEndDisposition } from '../../../../components/DispositionsModals/dispositionsHelper';
import featureFlags from '../../../../constants/featureFlags';
import { IHome, IOpportunity, IUIFlow } from '../../../../interfaces';
import { homeUsageTitle } from '../../../../interfaces/IHome';
import { OpportunityStatus } from '../../../../interfaces/IOpportunity';
import { QuestionType } from '../../../../interfaces/IWorkflow';
// eslint-disable-next-line max-len
import { useDisqualifyDataCollectionOpportunity } from '../../../../queries/leads/data_collection/useDataCollectionOpportunities';
import usePersonAssetQualification, {
  HOME_UNDERWRITING_QUESTIONS_QUALIFICATION_GROUP,
  PERSON_ASSET_QUALIFICATION_QUERY_KEY,
  Qualification
} from '../../../../queries/people/usePersonAssetQualification';
import {
  HOME_UNDERWRITING_FLOW_MAIN_FORM,
  HOME_UNDERWRITING_FLOW_MAIN_FORM_V3,
  saveAnswers as saveAnswersRequest
} from '../../../../queries/workflows/useUiFlow';
import colors from '../../../../theme/colors';
import { sizes, spacings } from '../../../../theme/variables';
import { isEmpty } from '../../../../utils/object';
import { REQUIRED_MESSAGE } from '../../../../utils/yupRules';
import { SyncRefAndValues } from '../_helpers';
import AutoSaveForm from './AutoSaveForm';
import DisqualificationTip from './DisqaulificationTip';
import DisqualificationMessage from './DisqualificationMessage';
import ScrollToFirstError from './ScrollToFirstError';

const HomeEligibilityQuestions = ({
  personHome,
  underwritingQuestionsUIFlow,
  openedByDefault,
  personGid,
  leadGid,
  leadId,
  saveAnswers,
  opportunity,
  setQualificationStatus,
  isDataEditingForbidden,
  isServiceLead,
  onLeadClosed,
  formRef
}: {
  personHome: IHome;
  underwritingQuestionsUIFlow: IUIFlow;
  openedByDefault: boolean;
  personGid: string;
  leadGid: string;
  leadId: number;
  saveAnswers: typeof saveAnswersRequest;
  opportunity: IOpportunity;
  setQualificationStatus: React.Dispatch<
    React.SetStateAction<Record<string, { isQualifying: boolean; qualification: Qualification | undefined }>>
  >;
  isDataEditingForbidden: boolean;
  isServiceLead: boolean;
  onLeadClosed: () => void;
  formRef: (element: any) => void;
}) => {
  const opportunityIsNotQualified = opportunity.status === OpportunityStatus.NotQualified;
  const opportunityDataCollectionEnabled = opportunity.is_data_collection_enabled;
  const [showQuestions, setShowQuestions] = React.useState(opportunityIsNotQualified ? false : openedByDefault);
  const uiFlowRef = React.useRef<IUIFlow>();

  const { mutateAsync: disqualifyOpportunity, isPending: isDisqualifyingOpportunity } =
    useDisqualifyDataCollectionOpportunity();

  const { data: qualification, isFetching: isQualifying } = usePersonAssetQualification({
    leadGid,
    personGid,
    assetGid: personHome.gid,
    qualificationGroupKey: opportunity.qualification_available
      ? HOME_UNDERWRITING_QUESTIONS_QUALIFICATION_GROUP
      : undefined,
    enabled: !(featureFlags.cstGuidedSellingExperience && isServiceLead)
  });

  React.useEffect(() => {
    setQualificationStatus(currentValue => ({
      ...currentValue,
      [personHome.gid]: {
        isQualifying,
        qualification
      }
    }));
  }, [qualification, setQualificationStatus, isQualifying, personHome.gid]);

  const queryClient = useQueryClient();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const autoSaveAnswers = React.useCallback(
    debounce((values: unknown, setSubmitting: (v: boolean) => void) => {
      const buildAnswers = (values: Record<string, any>) =>
        uiFlowRef.current!.answersForFormValues({
          personGid: personGid!,
          engagementGid: leadGid!,
          assetGid: personHome.gid,
          formValues: values
        }) || [];

      return saveAnswers({
        uiFlowKey: featureFlags.homeEligibilityPropertyUnderMaintenance
          ? HOME_UNDERWRITING_FLOW_MAIN_FORM_V3
          : HOME_UNDERWRITING_FLOW_MAIN_FORM,
        answers: buildAnswers({ ...uiFlowRef.current?.answers, ...(values as Record<string, any>) })
      })
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: [PERSON_ASSET_QUALIFICATION_QUERY_KEY, personGid, personHome.gid, leadGid]
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    }, 500),
    [personGid, leadGid, personHome.gid]
  );

  const isDisqualified = qualification?.result === 'disqualified';

  const requiredSchema = underwritingQuestionsUIFlow.visibleQuestions().reduce(
    (questions, question) => ({
      ...questions,
      [question.content.key]: yup.mixed().test({ message: REQUIRED_MESSAGE, test: value => !isEmpty(value) })
    }),
    {}
  );

  const isRequired =
    (!isDisqualified || !qualification) && !opportunityIsNotQualified && opportunityDataCollectionEnabled;
  const isDisabled = opportunityIsNotQualified || isDataEditingForbidden || !opportunityDataCollectionEnabled;

  const initialErrors = () => {
    if (!isRequired) {
      return undefined;
    }

    const values = underwritingQuestionsUIFlow.initialValues();
    return Object.keys(values).reduce((accumulator, key) => {
      if (!values[key]) {
        return { ...accumulator, [key]: REQUIRED_MESSAGE };
      }

      return accumulator;
    }, {});
  };

  return (
    <Container
      border
      borderWidth={2}
      borderColor={showQuestions ? colors.azure50 : colors.grey10}
      boxShadow={showQuestions}
      roundBorder
      backgroundColor={showQuestions ? colors.ghostWhite : 'transparent'}
      p={spacings.px24}
      mt={openedByDefault ? spacings.px12 : spacings.px24}
    >
      <SyncRefAndValues intermediateValuesRef={uiFlowRef} values={underwritingQuestionsUIFlow} />
      <FlexBox>
        <ButtonWithoutBorder
          onClick={() => setShowQuestions(current => !current)}
          disabled={isDisqualifyingOpportunity}
          customCss={css`
            border: none;
            padding: 0;
            background: transparent;
            &:hover {
              background: transparent;
              opacity: ${opportunityIsNotQualified ? 1 : 0.8};
            }

            &:disabled {
              background: transparent;
              cursor: not-allowed;

              &:hover {
                background: transparent;
              }
            }
          `}
        >
          <FlexBox gap={spacings.px4} alignItemsCenter>
            <ChevronRight
              css={css`
                transform: ${showQuestions ? 'rotate(-90deg)' : ''};
                transition: transform 0.2s;
                position: relative;
                right: ${spacings.px8}px;
              `}
              color={
                opportunityIsNotQualified || !opportunity.is_data_collection_enabled ? colors.grey30 : colors.black
              }
            />
            <FlexBox
              columnDirection
              customCss={css`
                text-align: left;
              `}
            >
              <FlexBox alignItemsCenter gap={spacings.px8}>
                {!opportunity.is_data_collection_enabled && (
                  <VisibilityOffIcon color={colors.black} width={spacings.px20} height={spacings.px20} />
                )}
                <Paragraph
                  type="large"
                  bold
                  color={
                    opportunityIsNotQualified || !opportunity.is_data_collection_enabled ? colors.grey60 : undefined
                  }
                >
                  {homeUsageTitle(personHome)}
                </Paragraph>
                {opportunityIsNotQualified && (
                  <>
                    <Paragraph
                      color={colors.statusRed}
                      data-tip
                      data-for={`${opportunity.id}-tip`}
                      customCss={css`
                        display: inline;
                      `}
                    >
                      <WarningIcon
                        css={css`
                          margin-right: ${spacings.px4}px;
                          vertical-align: text-top;
                        `}
                      />
                      Disqualified due to underwriting
                    </Paragraph>
                    <Tooltip id={`${opportunity.id}-tip`} theme="light">
                      <Paragraph
                        customCss={css`
                          max-width: 300px;
                        `}
                      >
                        {opportunity.disqualification_message}
                      </Paragraph>
                    </Tooltip>
                  </>
                )}
              </FlexBox>
              <FlexBox alignItemsCenter gap={spacings.px12}>
                <Text color={opportunityIsNotQualified ? colors.grey60 : undefined}>
                  <CopyableAddress address={personHome.address} />
                </Text>
                <HomeLinks personGid={personGid} homeGid={personHome.gid} />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </ButtonWithoutBorder>
      </FlexBox>
      <Formik
        innerRef={formRef}
        initialStatus={isDisabled ? 'disabled' : ''}
        initialValues={underwritingQuestionsUIFlow.initialValues()}
        validateOnBlur={false}
        initialErrors={initialErrors()}
        validate={() => (showQuestions ? undefined : setShowQuestions(true))}
        validationSchema={yup.object().shape(isRequired ? requiredSchema : underwritingQuestionsUIFlow.validations())}
        onSubmit={() => Promise.resolve(true)}
      >
        {({ values, isSubmitting, isValidating }) => {
          return (
            <>
              {showQuestions && (
                <Form>
                  <Container mh={spacings.px24}>
                    {!isDisabled && <AutoSaveForm autoSave={autoSaveAnswers} allowSavingNotValid={true} />}
                    {underwritingQuestionsUIFlow?.containers
                      ?.flatMap(({ elements }) => elements)
                      .filter(({ kind }) => kind === 'question')
                      .map(element => {
                        return underwritingQuestionsUIFlow.inputForQuestion({
                          element,
                          formValues: values,
                          propsProvider: ({ label, type, secondary, info, key }) => ({
                            inline: true,
                            key,
                            id: key,
                            ...(type === QuestionType.Radio
                              ? {
                                  label: () => (
                                    <FlexBox gap={spacings.px4} pt={secondary ? spacings.px8 : spacings.px24}>
                                      <Text
                                        bold={!secondary}
                                        customCss={css`
                                          ${isRequired ? `&::after { content: '*'; color: ${colors.statusRed}; }` : ''}
                                        `}
                                      >
                                        {label}
                                      </Text>
                                      {info && (
                                        <Tag
                                          label={info}
                                          backgroundColor={colors.violet}
                                          textColor={colors.violet}
                                          transparent
                                        />
                                      )}
                                      {qualification && (
                                        <DisqualificationTip qualification={qualification} questionKey={key} />
                                      )}
                                    </FlexBox>
                                  )
                                }
                              : {}),
                            ...([QuestionType.Select, QuestionType.MultiSelect].includes(type)
                              ? { inline: false, preserveErrorSpace: false }
                              : {}),
                            required: isRequired
                          }),
                          renderer: (input, question) => (
                            <React.Fragment key={`${personHome.gid}.${question.key}`}>
                              <FlexBox
                                gap={spacings.px8}
                                fitParentWidth={!question.secondary}
                                customCss={
                                  [QuestionType.Select, QuestionType.MultiSelect].includes(question.type)
                                    ? css`
                                        padding: ${spacings.px12}px 0;
                                        max-width: ${sizes.mediumFormInputWidth}px;
                                      `
                                    : undefined
                                }
                              >
                                {question.verification_status && (
                                  <QuestionVerificationStatusIcon
                                    verificationStatus={question.verification_status}
                                    disabled={isDisabled}
                                    css={css`
                                      margin-left: -${spacings.px24}px;
                                      margin-top: ${spacings.px28}px;
                                    `}
                                  />
                                )}
                                {input}
                              </FlexBox>
                              {qualification && (
                                <Container ml={spacings['-px24']}>
                                  <DisqualificationMessage qualification={qualification} questionKey={question.key} />
                                </Container>
                              )}
                            </React.Fragment>
                          )
                        });
                      })}
                  </Container>
                  {qualification?.result === 'disqualified' && (
                    <Button
                      data-testid="disqualify-home-button"
                      loading={isSubmitting || isValidating || isQualifying}
                      variant={ButtonVariant.Danger}
                      mt={spacings.px24}
                      onClick={async () => {
                        const response = await disqualifyOpportunity({
                          leadId,
                          opportunityId: opportunity.id,
                          disqualificationReason: qualification?.code,
                          disqualificationMessage: qualification?.agent_explanation
                        });

                        if (isEndDisposition(response.data_collection.current_disposition.disposition_type)) {
                          onLeadClosed();
                        }
                        setShowQuestions(false);
                      }}
                    >
                      Disqualify home
                    </Button>
                  )}
                  <ScrollToFirstError />
                  <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
                </Form>
              )}
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default HomeEligibilityQuestions;
