import { uniqBy } from 'ramda';

import featureFlags from '../../../../../constants/featureFlags';
import {
  IGroupedQuotes,
  IOpportunity,
  IOpportunityPolicies,
  IOpportunityPremiumPrediction,
  IQuote,
  Pretty
} from '../../../../../interfaces';
import { IAssetsQuotesGroup } from '../../../../../interfaces/IGroupedQuotes';
import { arePolicyTypesSimilar, PolicyType } from '../../../../../interfaces/IPolicyType';
import { IQuotesRequest } from '../../../../../interfaces/IQuotesRequests';
import { SourceProducts } from '../../../../../interfaces/ISourceDimensions';
import { applySavingsToQuote } from './quoteSavingsHelper';
import { applyUtmParamsToQuote } from './utmParamsHelper';

const floodQuotesForCurrentOpportunity = (quotes: IQuote[], opportunity: IOpportunity) => {
  const floodQuotes = quotes.filter(q => q.attributes.policy_type === PolicyType.Flood);

  return floodQuotes.filter(q => opportunity.assets?.some(asset => asset.gid === q.attributes.asset_gids?.[0]));
};

const sortGroupsByDescCreatedAtQuotes = (groupedQuotes: IGroupedQuotes<IAssetsQuotesGroup>) => {
  const getLatestQuoteDate = (group: IAssetsQuotesGroup) =>
    Math.max(...group.quotes.map(quote => new Date(quote.created_at).getTime()));

  groupedQuotes.groups.sort((a, b) => {
    const aDate = getLatestQuoteDate(a);
    const bDate = getLatestQuoteDate(b);

    return bDate - aDate;
  });
};

const augmentQuotesGroups = ({
  groupedQuotes,
  opportunitiesWithPremiumPredictions = [],
  personGid,
  leadGid,
  leadId,
  quotesSourceProduct
}: {
  groupedQuotes: IGroupedQuotes<IAssetsQuotesGroup> | undefined;
  opportunitiesWithPremiumPredictions:
    | Pretty<IOpportunity & IOpportunityPolicies & IOpportunityPremiumPrediction>[]
    | undefined;
  personGid: string;
  leadGid: string | undefined;
  leadId: number | undefined;
  quotesSourceProduct: IQuotesRequest['source_product'] | undefined;
}) => {
  if (!groupedQuotes) {
    return groupedQuotes;
  }

  const groupedQuotesCopy = structuredClone(groupedQuotes);
  const allQuotes = uniqBy(
    ({ gid }) => gid,
    groupedQuotesCopy.groups.flatMap(group => group.quotes)
  );

  if (featureFlags.groupedQuotesSorting) {
    sortGroupsByDescCreatedAtQuotes(groupedQuotes);
  }

  groupedQuotesCopy.groups = opportunitiesWithPremiumPredictions
    .map(enhancedOpportunity => {
      const originalGroup = groupedQuotes.groups.find(group => {
        const assetGidFromQuote = group.quotes.filter(q => q.attributes.policy_type !== PolicyType.Flood)[0]?.attributes
          ?.asset_gids?.[0];
        const policyType = group.quotes.filter(q => q.attributes.policy_type !== PolicyType.Flood)[0]?.attributes
          ?.policy_type;

        return (
          (!enhancedOpportunity.assets?.length ||
            !assetGidFromQuote ||
            enhancedOpportunity.assets?.some(asset => asset.gid === assetGidFromQuote)) &&
          arePolicyTypesSimilar(policyType!, enhancedOpportunity.policy_type)
        );
      });

      if (quotesSourceProduct !== SourceProducts.AgentManual) {
        return originalGroup ? { ...originalGroup, opportunity: enhancedOpportunity } : undefined;
      }

      return originalGroup
        ? { ...originalGroup, opportunity: enhancedOpportunity }
        : { title: enhancedOpportunity.title, quotes: [], opportunity: enhancedOpportunity };
    })
    .filter(Boolean)
    .filter(({ opportunity: { agent_selected_coverages }, quotes }) => {
      return quotesSourceProduct !== SourceProducts.AgentManual || !!agent_selected_coverages || !!quotes.length;
    });

  groupedQuotesCopy.groups = groupedQuotesCopy.groups.map(group => {
    if (group.opportunity.policy_type === PolicyType.Flood) {
      group.quotes = floodQuotesForCurrentOpportunity(allQuotes, group.opportunity);
    } else if (
      /*
        Currently BE responds with 2 Groups for single term life opportunity
        one is manual quotes (with asset attribute in quotes)
        and another is rc1 quotes (without asset attribute in quotes)
        If the opportunity is Term Life and there are only Term Life policy types in the response,
        we assume that all quotes belongs to this group
        Would break in case of multiple policy types in the future
      */
      group.opportunity.policy_type === PolicyType.TermLife &&
      groupedQuotesCopy.policy_types.length === 1 &&
      groupedQuotesCopy.policy_types[0]?.id === PolicyType.TermLife
    ) {
      group.quotes = allQuotes.filter(
        q => q.attributes.asset_gids?.[0] === group.opportunity.assets?.[0]?.gid || !q.attributes.asset_gids?.length
      );
    } else {
      group.quotes = group.quotes.filter(({ attributes }) => attributes.policy_type !== PolicyType.Flood);
    }

    group.quotes = group.quotes.map(quote => {
      quote.attributes.binding_url = applyUtmParamsToQuote({
        originalUrl: quote.attributes.binding_url,
        personGid,
        leadGid,
        leadId,
        assetGids: quote.attributes.asset_gids,
        policyType: quote.attributes.policy_type
      });

      quote.attributes.feedback_url = applyUtmParamsToQuote({
        originalUrl: quote.attributes.feedback_url,
        personGid,
        leadGid,
        leadId,
        assetGids: quote.attributes.asset_gids,
        policyType: quote.attributes.policy_type
      });

      return applySavingsToQuote(quote, group.opportunity);
    });

    return group;
  });

  return groupedQuotesCopy;
};

export const promoteNonIntegratedCarriers = ({ quotes }: { quotes: IQuote[] }) => {
  return [...quotes].sort((left, right) => {
    if (left.attributes.promotion_rank === right.attributes.promotion_rank) {
      return left.attributes.carrier.name.localeCompare(right.attributes.carrier.name);
    }

    return (left.attributes.promotion_rank || 0) > (right.attributes.promotion_rank || 0) ? -1 : 1;
  });
};

export default augmentQuotesGroups;
